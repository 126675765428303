import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MostrarEmpleos.css';
import { useNavigate } from 'react-router-dom';

const CargarCategorias = () => {

  const navigate = useNavigate();
  const [nombre, setNombre] = useState('');
  const [color, setColor] = useState('#000000'); 
  const [categorias, setCategorias] = useState([]);

  const cargarCategorias = async () => {
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/categorias');
      const data = await response.json();
      setCategorias(data);
    } catch (error) {
      console.error('Error al cargar las categorías:', error);
    }
  };

  const agregarCategoria = async () => {
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/categorias', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre, color }),
      });

      if (response.ok) {
        alert('Categoría agregada con éxito');
        cargarCategorias(); 
        setNombre('');
        setColor('#000000');
      } else {
        const data = await response.json();
        alert(data.error || 'Error al agregar la categoría');
      }
    } catch (error) {
      console.error('Error al agregar la categoría:', error);
    }
  };

  const eliminarCategoria = async (id) => {
    try {
      const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/categorias/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        alert('Categoría eliminada con éxito');
        cargarCategorias();
      } else {
        const data = await response.json();
        alert(data.error || 'Error al eliminar la categoría');
      }
    } catch (error) {
      console.error('Error al eliminar la categoría:', error);
    }
  };

  useEffect(() => {
    cargarCategorias();
  }, []);

  return (
    <div className="full-background" style={{ backgroundColor: '#1a237e', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
      <div  style={{ maxWidth: '1000px', padding: '30px', borderRadius: '10px' }}>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img 
            src="/CARRERAS.png" 
            alt="Admin Logo" 
            style={{ width: '300px', height: '300px', marginBottom: '20px' }} 
          />
        </div>
        <h2 className="text-white mb-4" style={{ textAlign: 'center' }}>Cargar Carrera</h2>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>
        <div className="form-group">
          <input 
            type="text" 
            className="form-control mb-3" 
            placeholder="Nombre de la categoría" 
            value={nombre} 
            onChange={(e) => setNombre(e.target.value)} 
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="colorPicker" className="form-label text-white">Seleccionar color</label>
          <input 
            type="color" 
            id="colorPicker"
            className="form-control form-control-color"
            value={color} 
            onChange={(e) => setColor(e.target.value)} 
            style={{ padding: '0.2rem' }}
          />
        </div>
        <button className="btn btn-primary mb-4 w-100" onClick={agregarCategoria}>Guardar</button>

        <ul className="list-group">
          {categorias.map((categoria) => (
            <li 
              key={categoria._id} 
              className="list-group-item d-flex justify-content-between align-items-center" 
              style={{ backgroundColor: categoria.color, color: 'white' }}
            >
              <span>{categoria.nombre}</span>
              <button 
                className="btn btn-danger btn-sm"
                onClick={() => eliminarCategoria(categoria._id)}
              >
                Eliminar
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CargarCategorias;
