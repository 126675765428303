import React, { useState, useEffect } from 'react';
import './MenuVertical.css'; // Archivo CSS para estilos
import { FaUser, FaFileAlt, FaListAlt, FaSignOutAlt, FaBars, FaTimes, FaHome } from 'react-icons/fa'; // Importamos íconos
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para la navegación
import Modal from 'react-modal'; // Importamos react-modal

// Configurar el elemento raíz para el modal
Modal.setAppElement('#root');

const MenuVertical = () => {
  const [menuExpandido, setMenuExpandido] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cvPath, setCvPath] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [usuario, setUsuario] = useState({ nombre: '', apellido: '' });
  const navigate = useNavigate();

  const manejarClickMenu = () => {
    setMenuExpandido(!menuExpandido);
  };

  const handleMiPerfilClick = () => {
    navigate('/usuario'); 
  };

  const handleCerrarSesion = () => {
    localStorage.removeItem('token'); 
    localStorage.removeItem('user'); 
  
    navigate('/'); 
  };

  const abrirModal = () => {
    verificarCv();
  };

  const cerrarModal = () => {
    setModalIsOpen(false);
    setArchivo(null);
  };

  const verificarCv = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/perfil', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();

      if (response.ok) {
        setUsuario({ nombre: data.nombre, apellido: data.apellido }); 
        if (data.cv) {
          setCvPath(data.cv);
          setModalIsOpen(true);
        } else {
          setModalIsOpen(true);
        }
      } else {
        alert(data.error || 'Error al verificar el CV.');
      }
    } catch (error) {
      console.error('Error al verificar el CV:', error);
      alert('Ocurrió un error al verificar el CV.');
    }
  };

  const manejarDescargaCv = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Debes iniciar sesión primero.');
        navigate('/login');
        return;
      }
  
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/cv', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error en la respuesta:', errorData);
        throw new Error('No se pudo descargar el CV.');
      }
  
      const data = await response.json();
      if (data.cvUrl) {
        const link = document.createElement('a');
        link.href = `https://apiurquiza.sistemasgenius.com/api/${data.cvUrl}`;
        link.setAttribute('download', 'CV.pdf'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('URL del CV no encontrada.');
      }
    } catch (error) {
      console.error('Error al descargar el CV:', error);
      alert('Ocurrió un error al descargar el CV.');
    }
  };

  const manejarCambioArchivo = (e) => {
    setArchivo(e.target.files[0]);
  };

  const manejarSubirCv = async () => {
    if (!archivo) {
      alert('Por favor, selecciona un archivo.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    const formData = new FormData();
    formData.append('cv', archivo); 
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/cv/upload', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        alert('CV subido exitosamente.');
        cerrarModal();
      } else {
        alert(data.error || 'Error al subir el CV.');
      }
    } catch (error) {
      console.error('Error al subir el CV:', error);
      alert('Ocurrió un error al subir el CV.');
    }
  };

  useEffect(() => {
    const fetchPerfil = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/perfil', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();

        if (response.ok) {
          setUsuario({ 
            nombre: data.nombre, 
            apellido: data.apellido, 
            nombreUsuario: data.nombreUsuario 
          });
        } else {
          alert(data.error || 'Error al obtener el perfil.');
        }
      } catch (error) {
        console.error('Error al obtener el perfil:', error);
      }
    };

    fetchPerfil();
  }, []);

  return (
    <div className={`menu-vertical ${menuExpandido ? 'expandido' : ''}`}>
      <div className="menu-toggle" onClick={manejarClickMenu}>
        {menuExpandido ? <FaTimes className="menu-toggle-icon" /> : <FaBars className="menu-toggle-icon" />}
      </div>
      {menuExpandido && (
        <ul className="menu-lista">
          <li className="menu-item" onClick={() => navigate('/inicio')}>
            <FaHome className="menu-icon" />
            <span>Inicio</span>
          </li>
          <li className="menu-item" onClick={handleMiPerfilClick}>
            <FaUser className="menu-icon" />
            {usuario.nombreUsuario ? usuario.nombreUsuario.split('@')[0] : 'Cargando...'}
          </li>
          <li className="menu-item" onClick={abrirModal}>
            <FaFileAlt className="menu-icon" />
            <span>Mi CV</span>
          </li>
          <li className="menu-item" onClick={() => navigate('/mispostulaciones')}>
            <FaListAlt className="menu-icon" />
            <span>Mis Postulaciones</span>
          </li>
          <li className="menu-item" onClick={handleCerrarSesion}>
            <FaSignOutAlt className="menu-icon" />
            <span>Cerrar Sesión</span>
          </li>
        </ul>
      )}

      {/* Modal para Gestionar el CV */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={cerrarModal}
        contentLabel="Gestión de CV"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1a237e',
            color: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Mi CV</h2>
        {cvPath ? (
          <div>
            <p style={{color:"white"}}>Tienes un CV cargado.</p>
            <button className="btn btn-success" onClick={manejarDescargaCv} style={{ marginRight: '10px' }}>
              Descargar CV
            </button>
            <button className="btn btn-warning" onClick={cerrarModal}>
              Cerrar
            </button>
          </div>
        ) : (
          <div>
            <p>Debes cargar tu CV previamente.</p>
            <input 
              type="file" 
              accept=".pdf,.doc,.docx" 
              onChange={manejarCambioArchivo} 
              style={{ marginBottom: '10px', width: '100%' }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className="btn btn-primary" onClick={manejarSubirCv} style={{ marginRight: '10px' }}>
                Cargar CV
              </button>
              <button className="btn btn-secondary" onClick={cerrarModal}>
                Cancelar
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default MenuVertical;
