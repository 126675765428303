import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from 'react-modal'; 
import { FaCheck, FaPaperPlane, FaTimes } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom'; 

Modal.setAppElement('#root');

const MostrarEmpleos = () => {
  const [empleos, setEmpleos] = useState([]);
  const [empleosPostulados, setEmpleosPostulados] = useState([]);
  const [categoriaFiltro, setCategoriaFiltro] = useState('');
  const [fechaFiltro, setFechaFiltro] = useState('');
  const [nombreEmpresaFiltro, setNombreEmpresaFiltro] = useState('');
  const [empresasDisponibles, setEmpresasDisponibles] = useState([]);
  const [categorias, setCategorias] = useState([]); 
  const [modalIsOpen, setModalIsOpen] = useState(false); 
  const [modalImage, setModalImage] = useState(null);
  const [nombre, setNombre] = useState(''); 
  const [apellido, setApellido] = useState(''); 

  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
    cargarCategorias(); 
    cargarPostulacionesUsuario();
    verificarNombreApellido(); // Verificar nombre y apellido al cargar el componente
  }, []);

  const cargarCategorias = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/categorias', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setCategorias(data); 
        cargarEmpleos(data); 
      } else {
        const errorData = await response.json();
        console.error('Error al cargar categorías:', errorData);
        alert('Error al cargar las categorías');
      }
    } catch (error) {
      console.error('Error al cargar categorías:', error);
      alert('Error al cargar las categorías');
    }
  };

  const cargarEmpleos = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/empleos', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const empleosConCategorias = data.map(empleo => ({
          ...empleo,
          categoriaNombre: empleo.categoria,
          categoriaColor: empleo.categoriaColor 
        }));

        setEmpleos(empleosConCategorias);
        
        const empresasUnicas = Array.from(new Set(empleosConCategorias.map(empleo => empleo.NombreEmpresa)));
        setEmpresasDisponibles(empresasUnicas);
      } else {
        const errorData = await response.json();
        console.error('Error al cargar empleos:', errorData);
        alert('Error al cargar los empleos');
      }
    } catch (error) {
      console.error('Error al cargar empleos:', error);
      alert('Error al cargar los empleos');
    }
  };

  const cargarPostulacionesUsuario = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/postulaciones', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const postuladosIds = data.map(postulacion => postulacion._id);
        setEmpleosPostulados(postuladosIds);
      } else {
        console.error('Error al cargar postulaciones del usuario.');
      }
    } catch (error) {
      console.error('Error al cargar postulaciones del usuario:', error);
    }
  };

  const guardarNombreApellido = async () => {
    if (!nombre.trim() || !apellido.trim()) {
      alert('Por favor completa ambos campos.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/usuario/actualizar', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ nombre, apellido })
      });

      if (response.ok) {
        alert('Nombre y apellido actualizados correctamente.');
        setModalIsOpen(false); 
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Error al actualizar el nombre y apellido');
      }
    } catch (error) {
      console.error('Error al actualizar el nombre y apellido:', error);
      alert('Ocurrió un error al actualizar el nombre y apellido.');
    }
  };

  const verificarNombreApellido = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/perfil', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      if (response.ok) {
        if (!data.usuario || !data.apellido) {
          setModalIsOpen(true); 
        } else {
          setNombre(data.usuario);
          setApellido(data.apellido);
        }
      } else {
        alert(data.error || 'Error al obtener el perfil.');
      }
    } catch (error) {
      console.error('Error al obtener el perfil:', error);
      alert('Ocurrió un error al obtener el perfil.');
    }
  };

  const manejarPostulacion = async (index) => {
    const empleoSeleccionado = empleos[index];
    const token = localStorage.getItem('token');
  
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/postular', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ empleoId: empleoSeleccionado._id })
      });
  
      if (response.ok) {
        alert('Te has postulado exitosamente al empleo.');
        const nuevosEmpleos = [...empleos];
        nuevosEmpleos[index].postulado = true;
        setEmpleos(nuevosEmpleos);
        setEmpleosPostulados([...empleosPostulados, empleoSeleccionado._id]);
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Error al postularse al empleo');
      }
    } catch (error) {
      console.error('Error al postularse al empleo:', error);
      alert('Ocurrió un error al postularse al empleo.');
    }
  };

  const mostrarImagenAmpliada = (imagen) => {
    setModalImage(imagen);
  };

  const cerrarModalImagen = () => {
    setModalImage(null);
  };

  const empleosFiltrados = empleos.filter(empleo => {
    const cumpleCategoria = categoriaFiltro ? empleo.categoria === categoriaFiltro : true;
    const empleoFecha = new Date(empleo.fecha).toISOString().split('T')[0];
    const cumpleFecha = fechaFiltro ? empleoFecha === fechaFiltro : true;
    const cumpleNombreEmpresa = nombreEmpresaFiltro ? empleo.NombreEmpresa === nombreEmpresaFiltro : true;
    return cumpleCategoria && cumpleFecha && cumpleNombreEmpresa;
  });

  return (
    <div className="mostrar-empleos" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 20)", maxWidth: '1050px', margin: '0 auto' }}>
<br />
      <img 
        src='/lo1.gif' 
        alt="Logo"
        style={{ width: '1015px', height: '400px', borderRadius: '10px', display: 'block', margin: '0 auto', marginBottom: '20px' }}
      />      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false} // Evita que el modal se cierre al hacer clic fuera de él
        contentLabel="Actualizar Nombre y Apellido"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#2e3b55', // Color que combina con el proyecto
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.5)',
            color: 'white'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Completar Información</h2>
        <div>
          <label>Nombre:</label>
          <input 
            type="text" 
            className="form-control" 
            value={nombre} 
            onChange={(e) => setNombre(e.target.value)} 
            required 
            style={{ backgroundColor: 'white', color: 'black' }}
          />
        </div>
        <div>
          <label>Apellido:</label>
          <input 
            type="text" 
            className="form-control" 
            value={apellido} 
            onChange={(e) => setApellido(e.target.value)} 
            required 
            style={{ backgroundColor: 'white', color: 'black' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <button className="btn btn-primary" onClick={guardarNombreApellido}>
            Guardar
          </button>
        </div>
      </Modal>

      {/* Mostrar empleos */}
      {empleosFiltrados.map((empleo, index) => (
        <div key={index} className="empleo-card" data-aos="fade-up">
          <div className="empleo-header">
            <img 
              src={`https://apiurquiza.sistemasgenius.com/api${empleo.imagen}`} 
              alt="Imagen del Empleo" 
              className="empleo-logo" 
              onClick={() => mostrarImagenAmpliada(`https://apiurquiza.sistemasgenius.com/api${empleo.imagen}`)} 
              style={{ cursor: 'pointer', width: '100px', height: '100px', objectFit: 'cover', borderRadius: '10px' }}
            />
            <div className="empleo-info">
              <h3>{empleo.Puesto}</h3>
              <h3>{empleo.NombreEmpresa}</h3>
              <h3 className="categoria" style={{ backgroundColor: empleo.categoriaColor, padding: '5px', borderRadius: '5px', display: 'inline-block', textAlign: 'center', minWidth: '100px', color: 'white' }}>
                {empleo.categoria}
              </h3>
            </div>
          </div>
          <div className="empleo-body">
            <p>{empleo.anotaciones}</p>
            <small>Email de la Empresa: {empleo.email}</small>
            <br />
            <small>Publicado el: {new Date(empleo.fecha).toLocaleDateString()}</small>
          </div>
          <div className="empleo-footer">
            <button 
              className="btn-postularme"
              onClick={() => manejarPostulacion(index)}
              disabled={empleosPostulados.includes(empleo._id)}
              style={{
                backgroundColor: empleosPostulados.includes(empleo._id) ? 'gray' : '#007bff',
                cursor: empleosPostulados.includes(empleo._id) ? 'not-allowed' : 'pointer',
                color: 'white'
              }}
            >
              {empleosPostulados.includes(empleo._id) ? (
                <><FaCheck /> Postulado</> 
              ) : (
                <><FaPaperPlane /> Postularme</>
              )}
            </button>
          </div>
        </div>
      ))}

      {/* Modal para la imagen ampliada */}
      <Modal
        isOpen={!!modalImage}
        onRequestClose={cerrarModalImagen}
        contentLabel="Imagen Ampliada"
        style={{
          content: {
            top: '50%',
            width: '35%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            borderRadius: '10px',
            backgroundColor: 'white',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <button 
          onClick={cerrarModalImagen} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer'
          }}
        >
          <FaTimes />
        </button>
        <img 
          src={modalImage} 
          alt="Imagen Ampliada" 
          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
        />
      </Modal>
    </div>
  );
};

export default MostrarEmpleos;
