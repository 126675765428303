import React from "react";
import MenuVertical from "./menuvertical";
import MostrarPostulaciones from "./Mpostulaciones";

const InicioPostulaciones =()=>{
    return (
        <div className="App inicio-container">
          <MenuVertical />
          <MostrarPostulaciones />


        </div>
      );
    }


export default InicioPostulaciones;