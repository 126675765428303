import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './login';
import Administrador from './administrador';
import Usuario from './usuario';
import CargarEmpleo from './Cempleo';
import Registro from './Registro';
import Inicio from './Inicio';
import MisPostulaciones from './postulaciones';
import Cadmin from './cadmin';
import CargarCategorias from './Ccategorias';
import InicioPostulaciones from './InicioPostulaciones';
import GestionarEmpleos from './Verempleos';
import MostrarUsuarios from './Verusuarios';
import CargarAdministrador from './cargaradmin';
import MostrarAdministradores from './Veradmins';
import ProtectedRoute from './ProtectedRoute'; // Importa el componente ProtectedRoute
import Envio from './Envio';
function App() {
  return (
    <Router>
      <div className="app">
        <header className="App-header"></header>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/usuario" element={<Usuario />} />
          <Route path="/Registro" element={<Registro />} />
          <Route path="/Inicio" element={<Inicio />} />
          <Route path="/Mispostulaciones" element={<InicioPostulaciones />} />
          <Route path="/postulaciones" element={<MisPostulaciones />} />

          <Route
            path="/admin"
            element={
              <ProtectedRoute roles={['admin', 'admincomun']}>
                <Administrador />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CargarEmpleo"
            element={
              <ProtectedRoute roles={['admin', 'admincomun']}>
                <CargarEmpleo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Gestionaradm"
            element={
              <ProtectedRoute roles={['admin']}>
                <Cadmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CargarCategorias"
            element={
              <ProtectedRoute roles={['admin', 'admincomun']}>
                <CargarCategorias />
              </ProtectedRoute>
            }
          />
          <Route
            path="/VerEmpleos"
            element={
              <ProtectedRoute roles={['admin', 'admincomun']}>
                <GestionarEmpleos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Mostrarusuarios"
            element={
              <ProtectedRoute roles={['admin', 'admincomun']}>
                <MostrarUsuarios />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CargarAdmin"
            element={
              <ProtectedRoute roles={['admin']}>
                <CargarAdministrador />
              </ProtectedRoute>
            }
          />
          <Route
            path="/VerAdmin"
            element={
              <ProtectedRoute roles={['admin']}>
                <MostrarAdministradores />
              </ProtectedRoute>
            }
          />
             <Route
            path="/Enviar"
            element={
              <ProtectedRoute roles={['admin', 'admincomun']}>
                <Envio />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
