import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './MostrarEmpleos.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const CargarAdministrador = () => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [dni, setDni] = useState('');
  const [email, setEmail] = useState('');
  const [contraseña, setContraseña] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inicializa AOS una vez que el componente se monta
  }, []);

  const handleGuardar = async () => {
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/administrador', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre, apellido, dni, email, contraseña }),
      });

      if (response.ok) {
        alert('Administrador agregado con éxito');
        setNombre('');
        setApellido('');
        setDni('');
        setEmail('');
        setContraseña('');
      } else {
        const data = await response.json();
        alert(data.error || 'Error al agregar el administrador');
      }
    } catch (error) {
      console.error('Error al agregar el administrador:', error);
    }
  };

  return (
    <div 
      className="full-background" 
      style={{ 
        padding: '20px', 
        backgroundColor: '#1a237e', 
        minHeight: '140vh', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        maxWidth: '2000px' // Ancho máximo aumentado
      }}
      data-aos="fade-up"
    >
      {/* Imagen centrada en la parte superior */}
      <div style={{ textAlign: 'center',}} data-aos="fade-down">
        <img 
          src="/superadmin.png" 
          alt="Admin Logo" 
          style={{ width: '225px', height: '225px' }} 
        />
      </div>

      {/* Título centrado en la parte superior */}
      <h2 
        className="text-center mb-4" 
        style={{ 
          color: 'white', 
          marginBottom: '20px', 
          padding: '10px', 
          borderRadius: '5px', 
          backgroundColor: '#1a237e' // Fondo del título para mejor contraste
        }}
        data-aos="fade-right"
      >
        Cargar Administrador
      </h2>
          <div style={{ textAlign: 'center' }} data-aos="fade-up">
        <button 
          className="btn btn-secondary " // Ancho del 50% del contenedor padre
          onClick={() => navigate(-1)}
        >
          Atrás
        </button>
      </div>
      <br></br>

      
      {/* Contenedor del formulario */}
      <div 
        className="container" 
        style={{ 
          maxWidth: '600px', 
          backgroundColor: 'white', 
          padding: '30px', 
          borderRadius: '10px', 
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.2)',
          textAlign: 'center' 
        }}
        data-aos="fade-up"
      >
        {/* Campo Nombre */}
        <div className="form-group mb-3">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Nombre" 
            value={nombre} 
            onChange={(e) => setNombre(e.target.value)} 
          />
        </div>
        
        {/* Campo Apellido */}
        <div className="form-group mb-3">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Apellido" 
            value={apellido} 
            onChange={(e) => setApellido(e.target.value)} 
          />
        </div>
        
        {/* Campo DNI */}
        <div className="form-group mb-3">
          <input 
            type="text" 
            className="form-control" 
            placeholder="DNI" 
            value={dni} 
            onChange={(e) => setDni(e.target.value)} 
          />
        </div>
        
        {/* Campo Email */}
        <div className="form-group mb-3">
          <input 
            type="email" 
            className="form-control" 
            placeholder="Email (será el usuario)" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        
        {/* Campo Contraseña */}
        <div className="form-group mb-4">
          <input 
            type="password" 
            className="form-control" 
            placeholder="Contraseña" 
            value={contraseña} 
            onChange={(e) => setContraseña(e.target.value)} 
          />
        </div>
        
        {/* Botón Guardar */}
        <button 
          className="btn btn-primary w-100 mb-4" 
          onClick={handleGuardar}
          data-aos="fade-up"
        >
          Guardar
        </button>
      </div>
      
  
    </div>
  );
};

export default CargarAdministrador;
