import React, { useEffect, useState } from "react";
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Modal from 'react-modal'; 
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root'); 

const Usuario = () => {
  const [usuario, setUsuario] = useState({ nombreUsuario: '', nombre: '', apellido: '', contraseña: '', cv: null });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalModificarDatos, setModalModificarDatos] = useState(false); // Estado para el modal de modificar datos
  const [archivo, setArchivo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
    obtenerPerfil();
  }, []);

  const obtenerPerfil = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }
  
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/perfil', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setUsuario({ 
          nombreUsuario: data.nombreUsuario, 
          nombre: data.usuario, 
          apellido: data.apellido, 
          contraseña: '', 
          cv: data.cv 
        });
        console.log("Datos del usuario establecidos correctamente:", {
          nombreUsuario: data.nombreUsuario,
          nombre: data.usuario,
          apellido: data.apellido
        });
      } else {
        alert(data.error || 'Error al obtener el perfil.');
      }
    } catch (error) {
      console.error('Error al obtener el perfil:', error);
      alert('Ocurrió un error al obtener el perfil.');
    }
  };

  const manejarDescargaCv = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Debes iniciar sesión primero.');
        navigate('/login');
        return;
      }
  
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/cv', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error en la respuesta:', errorData);
        throw new Error('No se pudo descargar el CV.');
      }
  
      const data = await response.json();
      if (data.cvUrl) {
        const link = document.createElement('a');
        link.href = `https://apiurquiza.sistemasgenius.com/api/${data.cvUrl}`;
        link.setAttribute('download', 'CV.pdf'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('URL del CV no encontrada.');
      }
    } catch (error) {
      console.error('Error al descargar el CV:', error);
      alert('Ocurrió un error al descargar el CV.');
    }
  };

  const manejarSubirCv = async () => {
    if (!archivo) {
      alert('Por favor, selecciona un archivo.');
      return;
    }
    
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    const formData = new FormData();
    formData.append('cv', archivo); 

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/cv/upload', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        alert('CV cargado/actualizado exitosamente.');
        setModalIsOpen(false);
        obtenerPerfil(); 
      } else {
        alert(data.error || 'Error al subir el CV.');
      }
    } catch (error) {
      console.error('Error al subir el CV:', error);
      alert('Ocurrió un error al subir el CV.');
    }
  };

  const manejarCambioArchivo = (e) => {
    setArchivo(e.target.files[0]);
  };

  const abrirModal = () => {
    setModalIsOpen(true);
  };

  const cerrarModal = () => {
    setModalIsOpen(false);
    setArchivo(null);
  };

  const manejarModificarCv = () => {
    if (usuario.cv) {
      abrirModal();
    } else {
      alert("DEBE CARGAR UN CV");
      abrirModal(); 
    }
  };

  const manejarVolverAtras = () => {
    navigate(-1); 
  };

  const abrirModalModificarDatos = () => {
    setModalModificarDatos(true);
  };

  const cerrarModalModificarDatos = () => {
    setModalModificarDatos(false);
  };

  const manejarActualizarDatos = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/usuario/modificar', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nombre: usuario.nombre,
          apellido: usuario.apellido,
          contraseña: usuario.contraseña
        })
      });

      const data = await response.json();

      if (response.ok) {
        alert('Datos actualizados exitosamente.');
        cerrarModalModificarDatos();
        obtenerPerfil();
      } else {
        alert(data.error || 'Error al actualizar los datos.');
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      alert('Ocurrió un error al actualizar los datos.');
    }
  };

  return (
    <div className="administrador" style={{ height: '120vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-aos="fade-up">
      <div className="admin" style={{ textAlign: 'center' }} data-aos="zoom-in">
        <img 
          src="/perfil.png" 
          alt="Admin Logo" 
          style={{ width: '300px', height: '300px', marginBottom: '20px' }} 
          data-aos="fade-down"
        />
        <h1 style={{ color: "white", textAlign: "center" }} data-aos="fade-right">BIENVENIDO</h1>
        <p style={{ color: "white" }}> {usuario.nombreUsuario.split('@')[0]}</p>
        <div className="btn-group-vertical" role="group" aria-label="Admin Menu" style={{ marginTop: '20px', display: 'inline-block' }} data-aos="fade-left">
          {usuario.cv ? (
            <>
              <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" onClick={manejarDescargaCv}>DESCARGAR MI CV</button>
              <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" onClick={manejarModificarCv}>MODIFICAR MI CV</button>
            </>
          ) : (
            <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" onClick={abrirModal}>CARGAR NUEVO CV</button>
          )}
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="100" onClick={abrirModalModificarDatos}>MODIFICAR DATOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="200" onClick={() => navigate('/')}>SALIR X</button>
          <button type="button" className="btn btn-secondary btn-register" data-aos="fade-up" onClick={manejarVolverAtras}>ATRÁS</button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={cerrarModal}
        contentLabel="Gestión de CV"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1a237e',
            color: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Subir/Modificar CV</h2>
        <input 
          type="file" 
          accept=".pdf,.doc,.docx" 
          onChange={manejarCambioArchivo} 
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className="btn btn-primary" onClick={manejarSubirCv} style={{ marginRight: '10px' }}>
            Subir CV
          </button>
          <button className="btn btn-secondary" onClick={cerrarModal}>
            Cancelar
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalModificarDatos}
        onRequestClose={cerrarModalModificarDatos}
        contentLabel="Modificar Datos"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1a237e',
            color: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Modificar Datos</h2>
        <input
          type="text"
          placeholder="Nombre"
          value={usuario.nombre}
          onChange={(e) => setUsuario({ ...usuario, nombre: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
          className="form-control"
        />
        <input
          type="text"
          placeholder="Apellido"
          value={usuario.apellido}
          onChange={(e) => setUsuario({ ...usuario, apellido: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
          className="form-control"
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={usuario.contraseña}
          onChange={(e) => setUsuario({ ...usuario, contraseña: e.target.value })}
          style={{ marginBottom: '10px', width: '100%' }}
          className="form-control"
        />
        <input
          type="text"
          placeholder="Email"
          value={usuario.nombreUsuario}
          readOnly
          style={{ marginBottom: '10px', width: '100%' }}
          className="form-control"
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className="btn btn-primary" onClick={manejarActualizarDatos}>
            Guardar
          </button>
          <button className="btn btn-secondary" onClick={cerrarModalModificarDatos}>
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Usuario;
