import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from 'react-modal'; 
import { FaEdit, FaTrash, FaUser } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

Modal.setAppElement('#root');

const MostrarUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
  const [nuevaContraseña, setNuevaContraseña] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
    cargarUsuarios();
  }, []);

  const cargarUsuarios = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/usuarios', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const usuariosFiltrados = data.filter(usuario => usuario.rol !== 'admin');
        setUsuarios(usuariosFiltrados);
      } else {
        alert('Error al cargar los usuarios');
      }
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
      alert('Error al cargar usuarios');
    }
  };

  const abrirModalEditar = (usuario) => {
    setUsuarioSeleccionado(usuario);
    setModalIsOpen(true);
  };

  const cerrarModal = () => {
    setModalIsOpen(false);
    setUsuarioSeleccionado(null);
    setNuevaContraseña('');
  };

  const manejarEliminacion = async (usuarioId) => {
    if (window.confirm('¿Estás seguro de eliminar este usuario?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/usuarios/${usuarioId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          alert('Usuario eliminado exitosamente');
          cargarUsuarios(); 
        } else {
          alert('Error al eliminar el usuario');
        }
      } catch (error) {
        console.error('Error al eliminar el usuario:', error);
        alert('Error al eliminar el usuario');
      }
    }
  };

  const manejarEdicion = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/usuarios/${usuarioSeleccionado._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ nombre: usuarioSeleccionado.nombre, apellido: usuarioSeleccionado.apellido, contraseña: nuevaContraseña })
      });

      if (response.ok) {
        alert('Usuario editado exitosamente');
        cargarUsuarios(); 
        cerrarModal();
      } else {
        alert('Error al editar el usuario');
      }
    } catch (error) {
      console.error('Error al editar el usuario:', error);
      alert('Error al editar el usuario');
    }
  };

  return (
    <div className="mostrar-usuarios" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.2)", maxWidth: '1200px', height: '1200px', margin: '0 auto', paddingBottom: '20px', backgroundColor: '#1a237e' }} data-aos="fade-up">
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img 
          src="/usuarios.png" 
          alt="Usuarios" 
          style={{ width: '225px', height: 'auto' }} 
          data-aos="zoom-in"
        />
      </div>
      <h2 style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1a237e', padding: '20px', borderRadius: '10px', marginBottom: '20px' }} data-aos="zoom-in">
       USUARIOS ({usuarios.length})
      </h2>
      
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>
      
      {usuarios.map((usuario, index) => (
        <div key={index} className="usuario-card" data-aos="fade-left" style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', padding: '20px', borderRadius: '10px', marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
          <FaUser style={{ fontSize: '50px', marginRight: '15px', color: '#1a237e' }} data-aos="flip-left" />
          <div className="usuario-info" style={{ flex: 1 }} data-aos="fade-right">
            <h3>{usuario.nombre} {usuario.apellido}</h3>
            <small>Email: {usuario.nombreUsuario}</small>
            <br />
            <small>Rol: {usuario.rol}</small>
          </div>
          <div className="usuario-footer">
            <button 
              className="btn" 
              onClick={() => abrirModalEditar(usuario)} 
              style={{ marginRight: '10px', backgroundColor: '#5868d1', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              <FaEdit /> Editar
            </button>
            <button 
              className="btn" 
              onClick={() => manejarEliminacion(usuario._id)}
              style={{ backgroundColor: '#e53935', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              <FaTrash /> Eliminar
            </button>
          </div>
        </div>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={cerrarModal}
        contentLabel="Editar Usuario"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '50%', 
            height: '80%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#1a237e',
            color: 'white',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
        data-aos="zoom-in"
      >
        {usuarioSeleccionado && (
          <div>
            <h2 style={{ textAlign: 'center' }}>Editar Usuario</h2>
            <div className="form-group" data-aos="fade-up">
              <label>Nombre:</label>
              <input 
                type="text" 
                className="form-control" 
                value={usuarioSeleccionado.nombre} 
                onChange={(e) => setUsuarioSeleccionado({ ...usuarioSeleccionado, nombre: e.target.value })}
              />
            </div>
            <div className="form-group" data-aos="fade-up">
              <label>Apellido:</label>
              <input 
                type="text" 
                className="form-control" 
                value={usuarioSeleccionado.apellido} 
                onChange={(e) => setUsuarioSeleccionado({ ...usuarioSeleccionado, apellido: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Nueva Contraseña:</label>
              <input 
                type="password" 
                className="form-control" 
                value={nuevaContraseña} 
                onChange={(e) => setNuevaContraseña(e.target.value)}
              />
            </div>
            <button 
              className="btn" 
              onClick={manejarEdicion} 
              style={{ marginRight: '10px', backgroundColor: '#5868d1', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              Guardar Cambios
            </button>
            <button 
              className="btn" 
              onClick={cerrarModal}
              style={{ backgroundColor: '#1a237e', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              Cancelar
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default MostrarUsuarios;
