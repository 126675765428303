import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const MostrarPostulaciones = () => {
  const [postulaciones, setPostulaciones] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 });
    cargarPostulaciones();
  }, []);

  const cargarPostulaciones = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/postulaciones', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setPostulaciones(data);
        console.log(data);
      } else {
        const errorData = await response.json();
        console.error('Error al cargar postulaciones:', errorData);
        alert('Error al cargar las postulaciones');
      }
    } catch (error) {
      console.error('Error al cargar postulaciones:', error);
      alert('Error al cargar las postulaciones');
    }
  };

  return (
    <div className="mostrar-postulaciones" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.2)", maxWidth: '1150px', margin: '0 auto' }}>
      <br />
      <h2 style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1a237e', padding: '10px', borderRadius: '10px' }}>Mis Postulaciones</h2>
      
      {postulaciones.length > 0 ? (
        postulaciones.map((postulacion, index) => (
          <div key={index} className="postulacion-card" data-aos="fade-up" style={{ backgroundColor: '#5868d1', margin: '20px', borderRadius: '10px', padding: '20px', color: 'white' }}>
            <div className="postulacion-header" style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              <img 
                src={`https://apiurquiza.sistemasgenius.com/api/${postulacion.imagen}`} 
                alt="Logo de la Empresa" 
                className="postulacion-logo" 
                style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '10px', marginRight: '15px' }}
              />
              <div className="postulacion-info">
                <h3>{postulacion.Puesto}</h3>
                <p>{postulacion.NombreEmpresa}</p>
                <span className="categoria" style={{ backgroundColor: postulacion.categoriaColor, color: 'white', padding: '2px 6px', borderRadius: '5px' }}>
                  {postulacion.categoria}
                </span>
              </div>
            </div>
            <div className="postulacion-body">
              <p>{postulacion.anotaciones}</p>
              <small>Email de la Empresa: {postulacion.email}</small>
              <br />
              <small>Postulado el: {new Date(postulacion.fecha).toLocaleDateString()}</small>
            </div>
          </div>
        ))
      ) : (
        <p style={{ textAlign: 'center', color: 'white' }}>No tienes postulaciones registradas.</p>
      )}
    </div>
  );
};

export default MostrarPostulaciones;
