import React, { useEffect } from "react";
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos de AOS
import { useNavigate } from "react-router-dom";

const Cadmin= () => {
const navigate =useNavigate()

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inicializa AOS con una duración de 1000ms para las animaciones
  }, []);

  const adminStyle = {
    color: "white",
    textAlign: "center" 
  };

  return (
    <div className="administrador" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-aos="fade-up">
      <div className="admin" style={{ textAlign: 'center' }} data-aos="zoom-in">
        <img 
          src="/superadmin.png" 
          alt="Admin Logo" 
          style={{ width: '225px', height: '225px', marginBottom: '20px' }} 
          data-aos="fade-down"
        />
        <h1 style={adminStyle} data-aos="fade-right">Gestor de Administradores</h1>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>
        <div className="btn-group-vertical" role="group" aria-label="Admin Menu" style={{ marginTop: '20px', display: 'inline-block' }} data-aos="fade-left">
        <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" onClick={()=> navigate("/CargarAdmin")}>Cargar Administrador</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" onClick={()=>navigate("/VerAdmin")}>Ver/Eliminar Administradores</button>

     
        </div>
      </div>
    </div>
  );
}

export default Cadmin;
