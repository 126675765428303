import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from 'react-modal'; 
import { FaPaperPlane, FaTimes } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

Modal.setAppElement('#root');

const Envio = () => {
  const [empleos, setEmpleos] = useState([]);
  const [cvModalIsOpen, setCvModalIsOpen] = useState(false); 
  const [empleoSeleccionado, setEmpleoSeleccionado] = useState(null);
  const [usuariosPostulados, setUsuariosPostulados] = useState([]); 

  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
    cargarEmpleos();
  }, []);

  const cargarEmpleos = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/empleos', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setEmpleos(data);
      } else {
        alert('Error al cargar los empleos');
      }
    } catch (error) {
      console.error('Error al cargar empleos:', error);
      alert('Error al cargar los empleos');
    }
  };

  const abrirCvModal = async (empleo) => {
    setEmpleoSeleccionado(empleo);
    setCvModalIsOpen(true);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/usuarios', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const usuarios = await response.json();
        const postulados = usuarios.filter(usuario => usuario.postulaciones.includes(empleo._id));
        setUsuariosPostulados(postulados);
      } else {
        alert('Error al cargar los usuarios');
      }
    } catch (error) {
      console.error('Error al cargar los usuarios:', error);
    }
  };

  const cerrarCvModal = () => {
    setCvModalIsOpen(false);
    setUsuariosPostulados([]);
  };

  const enviarCVs = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/enviar-cvs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          empleoId: empleoSeleccionado._id,
          usuariosIds: usuariosPostulados.map(usuario => usuario._id)
        })
      });

      if (response.ok) {
        alert('CVs enviados exitosamente');
        cerrarCvModal(); 
      } else {
        alert('Error al enviar los CVs');
      }
    } catch (error) {
      console.error('Error al enviar los CVs:', error);
      alert('Error al enviar los CVs');
    }
  };

  return (
    <div className="gestionar-empleos" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.2)", maxWidth: '1150px', margin: '0 auto', minHeight: '1500px' }}>
      <br />
      <h2 style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1a237e', padding: '10px', borderRadius: '10px' }}>ENVIAR CVS</h2>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>

      {empleos.map((empleo, index) => (
        <div key={index} className="empleo-card" data-aos="fade-up">
          <div className="empleo-header">
            <img 
              src={`https://apiurquiza.sistemasgenius.com/api/${empleo.imagen}`} 
              alt="Imagen del Empleo" 
              className="empleo-logo" 
              style={{ cursor: 'pointer', width: '100px', height: '100px', objectFit: 'cover', borderRadius: '10px' }}
            />
            <div className="empleo-info">
              <h3>{empleo.Puesto}</h3>
              <h3>{empleo.NombreEmpresa}</h3>
            </div>
          </div>
          <div className="empleo-footer">
            <button 
              className="btn btn-info"
              onClick={() => abrirCvModal(empleo)}
              style={{ backgroundColor: '#007bff', borderColor: '#007bff', color: 'white', marginLeft: '10px' }}
            >
              <FaPaperPlane /> Enviar CV
            </button>
          </div>
        </div>
      ))}

      {/* Enviar CVs Modal */}
      <Modal
        isOpen={cvModalIsOpen}
        onRequestClose={cerrarCvModal}
        contentLabel="Enviar CVs"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '60%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#1a237e',
            color: 'white',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <button 
          onClick={cerrarCvModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white'
          }}
        >
          <FaTimes />
        </button>
        <h2 style={{ textAlign: 'center', color: '#fff' }}>Enviar CVs</h2>
        <p style={{ color: '#fff' }}>Email de la empresa: <strong>{empleoSeleccionado?.email}</strong></p>
        <p style={{ color: '#fff' }}>Asunto: <strong>CVS PROPUESTA LABORAL</strong></p>
        <p style={{ color: '#fff' }}>Puesto: <strong>{empleoSeleccionado?.Puesto}</strong></p>
        <p style={{ color: '#fff' }}>Empresa: <strong>{empleoSeleccionado?.NombreEmpresa}</strong></p>
        <h3 style={{ color: '#fff' }}>CVs adjuntos:</h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {usuariosPostulados.length === 0 ? (
            <li style={{ color: '#fff' }}>No hay postulantes para este empleo.</li>
          ) : (
            usuariosPostulados.map((usuario, index) => (
              <li key={index} style={{ marginBottom: '10px', color: '#fff' }}>
                <p style={{ color: '#fff' }}>{usuario.nombre} {usuario.apellido}</p>
                {usuario.cv ? (
                  <a 
                    href={`https://apiurquiza.sistemasgenius.com/api/api/${usuario.cv}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ color: '#ffb300', textDecoration: 'underline' }}
                  >
                    Descargar CV
                  </a>
                ) : (
                  <p style={{ color: 'gray' }}>CV no disponible</p>
                )}
              </li>
            ))
          )}
        </ul>
        <button 
          className="btn btn-primary" 
          style={{ marginTop: '20px', backgroundColor: '#43a047', borderColor: '#43a047' }}
          onClick={enviarCVs} 
          disabled={usuariosPostulados.length === 0} 
        >
          <FaPaperPlane /> Enviar
        </button>
      </Modal>
    </div>
  );
};

export default Envio;
