// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, roles }) => {
  const user = JSON.parse(localStorage.getItem('user')); // Asegúrate de almacenar los datos del usuario al iniciar sesión

  if (!user || !roles.includes(user.rol)) {
    return <Navigate to="/" />; // Redirige al login si no está autenticado o no tiene el rol adecuado
  }

  return children;
};

export default ProtectedRoute;
