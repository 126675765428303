import React, { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; 


const CargarEmpleo = () => {
  const navigate = useNavigate(); 

  const [empresa, setEmpresa] = useState('');
  const [puesto, setPuesto] = useState('');
  const [categoria, setCategoria] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [imagen, setImagen] = useState(null);
  const [preview, setPreview] = useState(null);
  const [anotaciones, setAnotaciones] = useState('');
  const [correoElectronico, setCorreoElectronico] = useState('');
  const [selectedColor, setSelectedColor] = useState('#fff');

  useEffect(() => {
    const cargarCategorias = async () => {
      try {
        const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/categorias'); 
        const data = await response.json();
        setCategorias(data);
      } catch (error) {
        console.error('Error al cargar las categorías:', error);
      }
    };

    cargarCategorias();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagen(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCategoriaChange = (e) => {
    const selectedCategoriaId = e.target.value;
    setCategoria(selectedCategoriaId);
  
    const selectedCategoria = categorias.find(cat => cat._id === selectedCategoriaId);
    if (selectedCategoria) {
      setSelectedColor(selectedCategoria.color);
    }
  };
  

 const handleGuardar = async () => {
   try {
      if (!empresa || !puesto || !categoria || !correoElectronico || !anotaciones) {
         alert('Todos los campos son obligatorios');
         return;
      }

      const formData = new FormData();
      formData.append('NombreEmpresa', empresa);
      formData.append('Puesto', puesto);
      formData.append('categoria', categoria); // Cambiado de 'categoría' a 'categoria'
      formData.append('email', correoElectronico);
      formData.append('imagen', imagen); 
      formData.append('anotaciones', anotaciones);

      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/empleos2', {
         method: 'POST',
         body: formData,
      });

      if (response.ok) {
         alert('Empleo guardado exitosamente!');
         setEmpresa('');
         setPuesto('');
         setCategoria('');
         setAnotaciones('');
         setCorreoElectronico('');
         setImagen(null);
         setPreview(null);
      } else {
         const errorData = await response.json();
         console.error('Error al guardar el empleo:', errorData);
         alert('Error al guardar el empleo');
      }
   } catch (error) {
      console.error('Error al guardar el empleo:', error);
   }
};


  return (
    <div className="cargar-empleo" style={{ padding: '20px', maxWidth: '500px', margin: '0 auto', background: 'linear-gradient(135deg, #1a237e, #5868d1)', borderRadius: '10px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }} data-aos="fade-down">
        <img 
          src="/CARGAR.png" 
          alt="Admin Logo" 
          style={{ width: '200px', height: '200px' }} 
        />
      </div>
      <h2 style={{ color: 'white', textAlign: 'center' }}>Cargar Empleo</h2>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>
      
      
      <div className="form-group">
        <label style={{ color: 'white' }}>Nombre de la Empresa</label>
        <input 
          type="text" 
          className="form-control" 
          value={empresa} 
          onChange={(e) => setEmpresa(e.target.value)} 
          placeholder="Nombre de la Empresa"
        />
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Correo electrónico</label>
        <input
          className="form-control" 
          value={correoElectronico} 
          onChange={(e) => setCorreoElectronico(e.target.value)} 
          placeholder="Correo Electrónico"
        />
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Puesto</label>
        <input 
          type="text" 
          className="form-control" 
          value={puesto} 
          onChange={(e) => setPuesto(e.target.value)} 
          placeholder="Puesto"
        />
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Categorías</label>
        <select 
  className="form-control" 
  value={categoria} 
  onChange={handleCategoriaChange}
  style={{ backgroundColor: selectedColor, color: 'white' }}
>
  <option value="">Seleccione una categoría</option>
  {categorias.map((cat) => (
    <option key={cat._id} value={cat._id} style={{ backgroundColor: cat.color, color: 'white' }}>
      {cat.nombre}
    </option>
  ))}
</select>
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Cargar Imagen</label>
        <input 
          type="file" 
          className="form-control-file" 
          onChange={handleImageChange} 
          accept="image/*" // Aceptar solo archivos de imagen
        />
        {preview && (
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <img 
              src={preview} 
              alt="Vista Previa" 
              style={{ width: '100px', height: '100px', objectFit: 'cover', border: '1px solid #ccc' }} 
            />
          </div>
        )}
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Descripción</label>
        <textarea 
          className="form-control" 
          value={anotaciones} 
          onChange={(e) => setAnotaciones(e.target.value)} 
          placeholder="Anotaciones"
        />
      </div>

      <button className="btn btn-primary btn-block" onClick={handleGuardar}>
        Guardar
      </button>
    </div>
  );
}

export default CargarEmpleo;
