import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from 'react-modal'; 
import { FaEdit, FaTrash, FaUserTie } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

Modal.setAppElement('#root');

const MostrarAdministradores = () => {
  const [administradores, setAdministradores] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [adminSeleccionado, setAdminSeleccionado] = useState(null);
  const [nuevaContraseña, setNuevaContraseña] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
    cargarAdministradores();
  }, []);

  const cargarAdministradores = async () => {
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/administradores', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setAdministradores(data);
      } else {
        alert('Error al cargar los administradores');
      }
    } catch (error) {
      console.error('Error al cargar administradores:', error);
      alert('Error al cargar administradores');
    }
  };

  const abrirModalEditar = (admin) => {
    setAdminSeleccionado(admin);
    setModalIsOpen(true);
  };

  const cerrarModal = () => {
    setModalIsOpen(false);
    setAdminSeleccionado(null);
    setNuevaContraseña('');
  };

  const manejarEliminacion = async (adminId) => {
    if (window.confirm('¿Estás seguro de eliminar este administrador?')) {
      try {
        const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/administradores/${adminId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          alert('Administrador eliminado exitosamente');
          cargarAdministradores(); 
        } else {
          alert('Error al eliminar el administrador');
        }
      } catch (error) {
        console.error('Error al eliminar el administrador:', error);
        alert('Error al eliminar el administrador');
      }
    }
  };

  const manejarEdicion = async () => {
    try {
      const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/administradores/${adminSeleccionado._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nombre: adminSeleccionado.Nombre, apellido: adminSeleccionado.Apellido, contraseña: nuevaContraseña })
      });

      if (response.ok) {
        alert('Administrador editado exitosamente');
        cargarAdministradores(); 
        cerrarModal();
      } else {
        alert('Error al editar el administrador');
      }
    } catch (error) {
      console.error('Error al editar el administrador:', error);
      alert('Error al editar el administrador');
    }
  };

  return (
    <div className="mostrar-usuarios" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.2)", maxWidth: '1200px', height: '1000px', margin: '0 auto', paddingBottom: '20px', backgroundColor: '#1a237e' }} data-aos="fade-up">
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img 
          src="/x1.png" 
          alt="Administradores" 
          style={{ width: '225px', height: 'auto' }} 
          data-aos="zoom-in"
        />
      </div>
      <h2 style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1a237e', padding: '20px', borderRadius: '10px', marginBottom: '20px' }} data-aos="zoom-in">
       ADMINISTRADORES ({administradores.length})
      </h2>
      
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>
      
      {administradores.map((admin, index) => (
        <div key={index} className="usuario-card" data-aos="fade-left" style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', padding: '20px', borderRadius: '10px', marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
          <FaUserTie style={{ fontSize: '50px', marginRight: '15px', color: '#1a237e' }} data-aos="flip-left" />
          <div className="usuario-info" style={{ flex: 1 }} data-aos="fade-right">
            <h3>{admin.Nombre} {admin.Apellido}</h3>
            <small>DNI: {admin.DNI}</small><br />
            <small>Email: {admin.Email}</small><br />
            <small>Rol: {admin.Rol}</small>
          </div>
          <div className="usuario-footer">
            <button 
              className="btn" 
              onClick={() => abrirModalEditar(admin)} 
              style={{ marginRight: '10px', backgroundColor: '#5868d1', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              <FaEdit /> Editar
            </button>
            <button 
              className="btn" 
              onClick={() => manejarEliminacion(admin._id)}
              style={{ backgroundColor: '#e53935', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              <FaTrash /> Eliminar
            </button>
          </div>
        </div>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={cerrarModal}
        contentLabel="Editar Administrador"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '50%', 
            height: '80%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#1a237e',
            color: 'white',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
        data-aos="zoom-in"
      >
        {adminSeleccionado && (
          <div>
            <h2 style={{ textAlign: 'center' }}>Editar Administrador</h2>
            <div className="form-group" data-aos="fade-up">
              <label>Nombre:</label>
              <input 
                type="text" 
                className="form-control" 
                value={adminSeleccionado.Nombre} 
                onChange={(e) => setAdminSeleccionado({ ...adminSeleccionado, Nombre: e.target.value })}
              />
            </div>
            <div className="form-group" data-aos="fade-up">
              <label>Apellido:</label>
              <input 
                type="text" 
                className="form-control" 
                value={adminSeleccionado.Apellido} 
                onChange={(e) => setAdminSeleccionado({ ...adminSeleccionado, Apellido: e.target.value })}
              />
            </div>
            <div className="form-group" data-aos="fade-up">
              <label>DNI:</label>
              <input 
                type="text" 
                className="form-control" 
                value={adminSeleccionado.DNI} 
                onChange={(e) => setAdminSeleccionado({ ...adminSeleccionado, DNI: e.target.value })}
              />
            </div>
            <div className="form-group" data-aos="fade-up">
              <label>Email:</label>
              <input 
                type="text" 
                className="form-control" 
                value={adminSeleccionado.Email} 
                onChange={(e) => setAdminSeleccionado({ ...adminSeleccionado, Email: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Nueva Contraseña:</label>
              <input 
                type="password" 
                className="form-control" 
                value={nuevaContraseña} 
                onChange={(e) => setNuevaContraseña(e.target.value)}
              />
            </div>
            <button 
              className="btn" 
              onClick={manejarEdicion} 
              style={{ marginRight: '10px', backgroundColor: '#5868d1', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              Guardar Cambios
            </button>
            <button 
              className="btn" 
              onClick={cerrarModal}
              style={{ backgroundColor: '#1a237e', color: 'white', border: 'none' }}
              data-aos="zoom-in"
            >
              Cancelar
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default MostrarAdministradores;
