import React, { useEffect, useState } from 'react';
import './Login.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos de AOS
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const [usuario, setUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate(); // Usa useNavigate
  const images = ['1.png', '2.png', '3.png'];
  const imageChangeInterval = 5000; // Cambia la imagen cada 5 segundos

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, imageChangeInterval);

    return () => clearInterval(interval);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
  
    // Validar que los campos no estén vacíos
    if (!usuario || !contraseña) {
      alert('Por favor, completa todos los campos.');
      return;
    }
  
    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nombreUsuario: usuario, contraseña })
      });
    
      const data = await response.json();
    
      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data)); 
        alert('Inicio de sesión exitoso');
        
        if (data.rol === 'estudiante') {
          navigate('/inicio'); 
        } else if (data.rol === 'admin' || data.rol === 'admincomun') {
          navigate('/admin'); 
        } else {
          alert('Rol no reconocido');
        }
      } else {
        alert(data.error || 'Error al iniciar sesión');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Ocurrió un error al iniciar sesión');
    }
  };
  

  const handleRegistro = () => {
    navigate('/registro'); // Redirige a la página de registro
  };

  return (
    <div
      className={`login-page ${currentImage !== 0 ? 'transition' : ''}`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/${images[currentImage]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '120vw',
        height: '150vh',
        transition: 'background-image 1s ease-in-out',
        opacity: 0.8, // Opacidad de las imágenes
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column', // Alinear elementos verticalmente
      }}
    >
      <div className="login-container" data-aos="fade-up">
        <div className="login-box">
          <img 
            src="/ur.gif" 
            alt="Urquiza Logo" 
            className="img-fluid rounded-circle mx-auto d-block"   
            style={{ width: '200px', height: '200px' }} 
            data-aos="zoom-in"
          />
          <form onSubmit={handleLogin}>
            <br />
            <div className="form-group" data-aos="fade-right">
              <input
                id="usuario"
                className="form-control"
                aria-label="NOMBRE USUARIO"
                placeholder="Nombre de Usuario"
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)} 
              />
            </div>
            <div className="form-group" data-aos="fade-left">
              <input
                id="contraseña"
                type="password"
                className="form-control"
                aria-label="CONTRASEÑA"
                placeholder="Contraseña"
                value={contraseña}
                onChange={(e) => setContraseña(e.target.value)}
              />
            </div>
            <br />
            <button className="btn btn-primary btn-block" type="submit" data-aos="fade-up">
              INGRESAR
            </button>
            <br />
            <br />
            <button className="btn btn-primary btn-block" type="button" onClick={handleRegistro} data-aos="fade-up">
              REGISTRARSE
            </button>
          </form>
        </div>
      </div>
      <br /><br /><br />

      <div className="text-center mt-4" 
        data-aos="flip-left"
        style={{
          backgroundColor: 'white', 
          color: 'blue', 
          padding: '20px',
          borderRadius: '10px',
          width: '60%', 
          maxWidth: '600px',
          margin: '0 auto',
          fontFamily: 'Roboto, sans-serif', 
          boxShadow: '0 4px 30px rgba(0, 0, 0, 20)', 
        }}>
        <p style={{color:"blue"}}>PROYECTO PRÁCTICA PROFESIONALIZANTE</p>
        <p style={{color:"blue"}}>PROF. JORGE MONDELO</p>
        <p>ALUMNOS: LEANDRO LINARES SILVA, ISMAEL FERRARI, LUCIANO RODAS, JOEL MANSILLA</p>
      </div>
    </div>
  );
};

export default Login;
